body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DK Douceur';
  src: local('Dk Douceur'), url(./assets/fonts/DK\ Douceur.otf) format('opentype');}

@font-face {
  font-family: 'Kalam';
  src: local('Kalam'), url(./assets/fonts/Kalam-Regular.ttf) format('truetype');}


model-viewer {
  background-color: #74EBD5;
  background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);

  width: 100%;
  height: 100%;
}
